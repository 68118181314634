#order {
    justify-content: center;
    padding: 1vh;
    width: 100%;
}

#order .product {
    vertical-align: top;
}

#order .product .MuiCardHeader-root{
    /* height: 0.5vw !important;
    padding: 5%;
    display: block; */
}

#order .product .text {
    display: flex;
}

#order .product .MuiCardHeader-root.MuiCardHeader-spacing{
    height: 1vw !important;
}

#order .product .MuiCardActions-root.MuiCardActions-spacing {
    direction: rtl;
}

#order .product .MuiCardActions-root {
    justify-content: center;
}

#order .text {
    color: rgb(2366,10,107) !important;
    font-size: 1.5rem;
    display: -webkit-box;
}

#order .text span.MuiTypography-root {
    font-size: 4vw;
    align-self: baseline;
}

#order span.MuiTypography-root {
    font-size: 3vw;
}

#order .finish{
    text-align: center;
    justify-content: center;
    color: white;
    background-color: rgb(218, 218, 218);
}

#order .finish.active{
    color: white;
    background-color: rgb(2366,10,107);
    cursor: pointer;
}