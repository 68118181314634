.diy {
    width: 80%;
    justify-self: center;
    display: inline-grid;
    padding: 2%;
    max-width: 500px;
    justify-content: center;
}

.products {
    display: inline-block;
}


.diy .templates {
    width: 100%;
    display: -webkit-inline-box;
    overflow: scroll;
}

.diy .template {
    height: 12vw;
    width: 12vw;
    margin: 1%;
    background-size: cover;
    max-width: 80px;
    max-height: 80px;
}

.diy .template .stripe {
    width: 100%;
    height: 40%;
    background-size: cover;
}

.diy .MuiCardContent-root {
    padding: 16px;
    height: 20vw;
}

.diy .products .product {
    width: 22vw;
}

.diy .price.MuiCardHeader-root {
    height: 0.5vw;
}

.diy .product .MuiCardHeader-root {
    height: 3vw !important;
}

.diy .main-product.MuiCardMedia-root {
    height: 15vh !important;
}

.diy .field.MuiCardHeader-root {
    position: inherit;
    width: 95%;
    align-items: center;
    text-align: right;
    padding: 1.5vw;
}

.template.selected {
    border-style: solid;
    border-width: 1.5px 1.5px 1.5px 1.5px;
    border-color: black;
    box-shadow: 2px 2px 2px rgba(44, 43, 43, 0.479);
}

@font-face {
    font-family: 'lupa_pinkyregular';
    src: url('../../Fonts/lupa_pinky-webfont.eot');
    src: url('../../Fonts/lupa_pinky-webfont.eot?#iefix') format('embedded-opentype'),
         url('../../Fonts/lupa_pinky-webfont.woff2') format('woff2'),
         url('../../Fonts/lupa_pinky-webfont.woff') format('woff'),
         url('../../Fonts/lupa_pinky-webfont.ttf') format('truetype'),
         url('../../Fonts/lupa_pinky-webfont.svg#lupa_pinkyregular') format('svg');
    font-weight: normal;
    font-style: normal;
}

.MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated {
    font-family: lupa_pinkyregular;
    color: rgb(2366,10,107);    
    direction: rtl;
}

.text-field {
    direction: rtl;
    justify-content: center;
}

.text-field .MuiFormControl-root.MuiTextField-root{
    width: 95%;
}

.MuiInput-underline:after { 
    border-bottom: solid 2px rgb(2366,10,107) !important;
}  

.MuiInputBase-input.MuiInput-input {
    font-family: 'Lupa Pinky';
    src: local('Lupa Pinky'), url(../../Fonts/Lupa\ Pinky.ttf) format('woff');
    font-size: 4vh;
}

.with-text {
    color: rgb(2366,10,107) !important;
}

.diy .product-type {

}

.diy .product-type-card {
    height: 50%;
}

.diy .product-type-card .MuiFormControl-root {
    width: 100%;
}

.PrivateNotchedOutline-legendLabelled-219.PrivateNotchedOutline-legendNotched-220 {
    width: 100%;
    text-align: center;
}

.diy .MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-shrink.MuiInputLabel-outlined.MuiFormLabel-filled {
    padding: 0 !important;
    margin-left: 17% !important;
    text-align: right;
    width: 100%;
    justify-content: center;
}

.diy .MuiList-root.MuiMenu-list.MuiList-padding {
    direction: rtl;
}

.MuiButtonBase-root.MuiListItem-root.MuiMenuItem-root.MuiMenuItem-gutters.MuiListItem-gutters.MuiListItem-button {
    font-family: lupa_pinkyregular !important;
    justify-content: space-between;
}

.MuiButtonBase-root.MuiListItem-root.MuiMenuItem-root.MuiMenuItem-gutters.MuiListItem-gutters.MuiListItem-button .size{
    font-size: 80%;
}

.MuiSelect-root.MuiSelect-select.MuiSelect-selectMenu.MuiSelect-outlined.MuiInputBase-input.MuiOutlinedInput-input {
    font-family: lupa_pinkyregular !important;
    justify-content: space-between !important;
    text-align: right;
}

.MuiPaper-root.MuiMenu-paper.MuiPopover-paper.MuiPaper-elevation8.MuiPaper-rounded {
    
}

.picker {
    display: inline-flex;
    width: 100%;
    justify-content: center;
}

.picker .MuiFormControl-root {
    width: 20%;
    align-items: flex-start;
}
.product-type-card {
    display: inline-flex;
    position: relative;
}
.picker .MuiAvatar-root {
    width: 40px;
    height: 40px;
    display: flex;
    overflow: hidden;
    position: relative;
    font-size: 1.25rem;
    align-items: center;
    flex-shrink: 0;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    line-height: 1;
    display: inline-block;
    user-select: none;
    border-radius: 50%;
    justify-content: center;
    align-self: center;
    height: 1;
}

.diy .MuiSvgIcon-root {
    vertical-align: -webkit-baseline-middle;
}

.diy .template.type {
    opacity: 0.8;
    font-size: 120%;
    color: white
}

.MuiSwitch-root {
    overflow: initial !important;
}

.align {
    height: 10vw !important;
    justify-content: center;
}

.align .div .MuiTypography-root.MuiTypography-body1{
   display: inline-block;
}

.MuiGrid-root.MuiGrid-container.MuiGrid-spacing-xs-1.MuiGrid-align-items-xs-center {
    justify-content: center;
}

.MuiGrid-root.MuiGrid-item {
   font-family: lupa_pinkyregular !important;
}

.MuiSwitch-track {
    background-color: rgb(2366,10,107) !important;
    border-color: rgb(2366,10,107) !important;
}