@font-face {
    font-family: 'lupa_pinkyregular';
    src: url('../../Fonts/lupa_pinky-webfont.eot');
    src: url('../../Fonts/lupa_pinky-webfont.eot?#iefix') format('embedded-opentype'),
         url('../../Fonts/lupa_pinky-webfont.woff2') format('woff2'),
         url('../../Fonts/lupa_pinky-webfont.woff') format('woff'),
         url('../../Fonts/lupa_pinky-webfont.ttf') format('truetype'),
         url('../../Fonts/lupa_pinky-webfont.svg#lupa_pinkyregular') format('svg');
    font-weight: normal;
    font-style: normal;
}

span.MuiTypography-root{
  font-family: lupa_pinkyregular;
  /* src: local('Lupa Pinky'), url(../../Fonts/Lupa\ Pinky.ttf) format('woff'); */
}

#catalog {
    justify-content: center;
    padding: 1vw;
    width: 100%;
}

.product {
    justify-content: center;
    width: 30vw;
    display: inline-block;
    padding: 5vw;
}

.product .MuiCardHeader-root {
    height: 7vw;
    display: contents;
}

.makeStyles-root-66 {
    max-width: none !important;
}

.product .makeStyles-root-122 {
    max-width: none !important;
}

.product .makeStyles-media-67 {
    height: 0;
    padding-top: 56.25%;
    height: 6vh;
}

#back-arrow {
    width: 3vh;
    display: flex;
    float: right;
    direction: rtl;
    width: 100%;
}