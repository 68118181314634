.title-content.MuiDialogContent-root {
    display: inline-flex;
    justify-content: center;
}

.pink {
    color: rgb(2366,10,107);
}

@font-face {
    font-family: 'lupa_pinkyregular';
    src: url('../../Fonts/lupa_pinky-webfont.eot');
    src: url('../../Fonts/lupa_pinky-webfont.eot?#iefix') format('embedded-opentype'),
         url('../../Fonts/lupa_pinky-webfont.woff2') format('woff2'),
         url('../../Fonts/lupa_pinky-webfont.woff') format('woff'),
         url('../../Fonts/lupa_pinky-webfont.ttf') format('truetype'),
         url('../../Fonts/lupa_pinky-webfont.svg#lupa_pinkyregular') format('svg');
    font-weight: normal;
    font-style: normal;
}

.MuiDialogContent-root.MuiDialogContent-dividers p{
    font-family: lupa_pinkyregular;
}

.MuiDialogContent-root.text-content.MuiDialogContent-dividers {
    text-align: center;
}

@font-face {
    font-family: 'lupa_pinkyregular';
    src: url('../../Fonts/lupa_pinky-webfont.eot');
    src: url('../../Fonts/lupa_pinky-webfont.eot?#iefix') format('embedded-opentype'),
         url('../../Fonts/lupa_pinky-webfont.woff2') format('woff2'),
         url('../../Fonts/lupa_pinky-webfont.woff') format('woff'),
         url('../../Fonts/lupa_pinky-webfont.ttf') format('truetype'),
         url('../../Fonts/lupa_pinky-webfont.svg#lupa_pinkyregular') format('svg');
    font-weight: normal;
    font-style: normal;
}
  
.MuiTypography-h6{    
  font-family: lupa_pinkyregular !important;
}

.MuiTypography-h6 {
    text-align: center;
    justify-content: center !important;
}

.MuiDialog-paperWidthSm {
    max-width: 600px;
    width: 60vw;
}

.MuiButtonBase-root.MuiListItem-root.center.MuiListItem-gutters.MuiListItem-button {
    justify-content: center;
}

.MuiButtonBase-root.MuiListItem-root.field.MuiListItem-gutters.MuiListItem-button {
    display: inline-flex;
    direction: rtl;
    align-items: baseline;
    justify-content: space-between;
    float: right;
    width: 100%;
}

.MuiButtonBase-root.MuiListItem-root.field.flicker.MuiListItem-gutters.MuiListItem-button {
    animation: linear 2s flicker infinite;
    background-color: lightgray;
}

@keyframes flicker {
    0% {
        background-color: lightgray;
    }
    50% {
        background-color: rgb(255, 255, 255);
    }
}

.MuiFormControl-root.MuiTextField-root {
    width: 90%;
    text-align: right;
}

.MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated {
    width: 100%;
}

@font-face {
    font-family: 'lupa_pinkyregular';
    src: url('../../Fonts/lupa_pinky-webfont.eot');
    src: url('../../Fonts/lupa_pinky-webfont.eot?#iefix') format('embedded-opentype'),
         url('../../Fonts/lupa_pinky-webfont.woff2') format('woff2'),
         url('../../Fonts/lupa_pinky-webfont.woff') format('woff'),
         url('../../Fonts/lupa_pinky-webfont.ttf') format('truetype'),
         url('../../Fonts/lupa_pinky-webfont.svg#lupa_pinkyregular') format('svg');
    font-weight: normal;
    font-style: normal;
}
  
.MuiTypography-h6{    
  font-family: lupa_pinkyregular !important;
}

.MuiTypography-h6 {
    text-align: center;
    justify-content: center !important;
}

.MuiDialog-paperWidthSm {
    max-width: 600px;
    width: 60vw;
}

.MuiButtonBase-root.MuiListItem-root.center.MuiListItem-gutters.MuiListItem-button {
    justify-content: center;
}

.MuiButtonBase-root.MuiListItem-root.field.MuiListItem-gutters.MuiListItem-button {
    display: inline-flex;
    direction: rtl;
    align-items: baseline;
    justify-content: space-between;
    float: right;
    width: 100%;
}

.MuiFormControl-root.MuiTextField-root {
    width: 90%;
    text-align: right;
}

.MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated {
    width: 100%;
}

.MuiButtonBase-root.MuiListItem-root.center.MuiListItem-gutters.MuiListItem-button.pink{
    color: rgb(2366,10,107);
}

.MuiButtonBase-root.MuiListItem-root.center.MuiListItem-gutters.MuiListItem-button {
    text-align: center;
}

.MuiButtonBase-root.MuiListItem-root.center.MuiListItem-gutters.MuiListItem-button.icons {
    justify-content: space-evenly;
    display: flex;
}

.MuiAvatar-root.MuiAvatar-circle.instagram.MuiAvatar-colorDefault {
    background-image: url('../../assets/instagram.jpg');
    background-size: cover;
}

.instagram .MuiTypography-colorPrimary {
    color: white !important;
}

.facebook .MuiTypography-colorPrimary {
    color: white;
}

.MuiAvatar-root.MuiAvatar-circle.facebook.MuiAvatar-colorDefault {
    background-color: rgb(41, 41, 139) !important;
}

.links .MuiListItem-root.MuiListItem-gutters {
    justify-content: start;
}