@font-face {
    font-family: 'lupa_pinkyregular';
    src: url('../../Fonts/lupa_pinky-webfont.eot');
    src: url('../../Fonts/lupa_pinky-webfont.eot?#iefix') format('embedded-opentype'),
         url('../../Fonts/lupa_pinky-webfont.woff2') format('woff2'),
         url('../../Fonts/lupa_pinky-webfont.woff') format('woff'),
         url('../../Fonts/lupa_pinky-webfont.ttf') format('truetype'),
         url('../../Fonts/lupa_pinky-webfont.svg#lupa_pinkyregular') format('svg');
    font-weight: normal;
    font-style: normal;
}
  
.MuiTypography-h6{    
  font-family: lupa_pinkyregular !important;
}

.MuiTypography-h6 {
    text-align: center;
    justify-content: center !important;
}

.MuiDialog-paperWidthSm {
    max-width: 10000px;
    width: 80vw !important;
}

.MuiButtonBase-root.MuiListItem-root.center.MuiListItem-gutters.MuiListItem-button {
    justify-content: center;
}

.MuiButtonBase-root.MuiListItem-root.field.MuiListItem-gutters.MuiListItem-button {
    display: inline-flex;
    direction: rtl;
    align-items: baseline;
    justify-content: space-between;
    float: right;
    width: 100%;
}

.MuiButtonBase-root.MuiListItem-root.field.flicker.MuiListItem-gutters.MuiListItem-button {
    animation: linear 2s flicker infinite;
    background-color: lightgray;
}

@keyframes flicker {
    0% {
        background-color: lightgray;
    }
    50% {
        background-color: rgb(255, 255, 255);
    }
}

.MuiFormControl-root.MuiTextField-root {
    width: 90%;
    text-align: right;
}

.MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated {
    width: 100%;
}


.MuiListItem-root.center.MuiListItem-gutters.price{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
}

.discount .MuiTypography-root.MuiListItemText-primary.MuiTypography-body1 {
    font-size: 0.9rem;
    color:rgb(2366,10,107);
}

.total .MuiTypography-root.MuiListItemText-primary.MuiTypography-body1 {
    font-size: 1.2rem;
    color: rgb(116, 113, 113);
}