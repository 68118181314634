.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

@font-face {
    font-family: 'lupa_pinkyregular';
    src: url('./Fonts/lupa_pinky-webfont.eot');
    src: url('./Fonts/lupa_pinky-webfont.eot?#iefix') format('embedded-opentype'), url('./Fonts/lupa_pinky-webfont.woff2') format('woff2'), url('./Fonts/lupa_pinky-webfont.woff') format('woff'), url('./Fonts/lupa_pinky-webfont.ttf') format('truetype'), url('./Fonts/lupa_pinky-webfont.svg#lupa_pinkyregular') format('svg');
    font-weight: normal;
    font-style: normal;
}

.App {
    font-family: lupa_pinkyregular;
}

@font-face {
    font-family: 'calibri';
    src: url('./Fonts/calibri.ttf');
    font-weight: normal;
    font-style: normal;
}

.App #logo {
    width: 100%;
    position: fixed;
    display: block;
    align-items: center;
    align-content: center;
    justify-content: center;
    justify-items: center;
}

.App .sew-icon img,
.App img.app-logo-img {
    width: 15vh;
    align-self: center;
    justify-self: center;
}

.App .Mui-selected {
    color: rgb(2366, 10, 107);
}

.content {
    display: flex;
    width: 100%;
    margin-top: 20vh;
}

.App .rights {
    position: fixed;
    display: block;
    align-self: flex-end;
    align-items: center;
    align-content: center;
    justify-content: center;
    justify-items: center;
}

.show-added-icon {
    animation: linear 0.5s jump;
    visibility: hidden;
    color: rgb(2366, 10, 107);
}

@keyframes jump {
    0% {
        visibility: hidden;
    }
}

.cart-count {
    position: absolute;
    width: 33%;
    justify-content: center !important;
    display: inline-flex;
    left: 2%;
    top: 5%;
    align-items: flex-start;
}

.cart-count .MuiAvatar-root {
    width: 3vh !important;
    height: 3vh !important;
    font-size: 1.5vh !important;
    background-color: rgba(128, 128, 128, 0.568);
}

.App .info-icon {
    width: 100%;
    position: absolute;
    justify-content: left;
}

.App .sew-icon {
    right: 0;
    position: fixed;
    justify-content: right;
}

.App .info-icon .MuiSvgIcon-root {
    display: block !important;
    margin: 1%;
    color: rgb(2366, 10, 107);
}

.App .navbar-fixed {
    position: fixed;
    width: 100%;
    z-index: 100;
    background-color: white;
    top: 0
}