#gallery {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-family: 'calibri';
}


/* 
#gallery .picture-card {
    width: 70%;
    height: 60vw;
    z-index: -1;
    margin-top: -50%;
    animation: flow-in 4s infinite;
}

#gallery .like {
    height: 60vw;
    z-index: 2;
    margin-top: -60%;
    align-items: center;
    position: absolute;
    justify-content: center;
    display: flex;
    animation: heart 8s infinite;
    animation-delay: 2s;
    opacity: 0;
}

#gallery .like .like-icon {
    font-size: 25vw;
    color: rgba(255, 255, 255, 0.89);
    margin-top: -5vw;
}

@keyframes flow-in{
    0% {
        margin-top: -40%;
        opacity: 0;
    }
    50% {
        margin-top: -70%;
        opacity: 0.8;
    }
    90% {
        margin-top: -70%;
        opacity: 1;
    }
    100% {
        margin-top: -100%;
        opacity: 0;
    }
}

@keyframes heart{
    0% {
        opacity: 0;
    }
    10% {
        opacity: 1;  
    }
    25% {
        opacity: 0;
    }
    100% {
        opacity: 0;
    }
}

#gallery .second-picture {
    width: 80%;
    height: 30vw;
    z-index: 100;
    animation: opacity 4s infinite;
    animation-delay: 2s;
}

@keyframes opacity {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

#gallery .left-picture, #gallery .right-picture {
    width: 20vw;
    height: 20vw;
    position: absolute;
}

#gallery .left-picture {
    animation: to-left 4s infinite;
    animation-delay: 1s;
    left: 20%;
}

#gallery .right-picture {
    animation: main-to-right 4s infinite;
    animation-delay: 1s;
    right: 20%;
    z-index: -1;
}
 
@keyframes to-main {
    0% {
        width: 20vw;
        height: 20vw;
        margin-left: -20%;
    };
    100% {
        width: 30vw;
        height: 30vw;
    }
}

@keyframes main-to-right {
    0% {
        width: 30vw;
        height: 30vw;
        margin-right: 10%;
    };
    100% {
        width: 20vw;
        height: 20vw;
    }
}

@keyframes to-left {
    0% {
        opacity: 0;
        margin-right: 10%;
    };
    100% {
        opacity: 1;
    }
}

#gallery .comments {
    position: absolute;
    display: flex;
    margin-top: 25%;
    flex-direction: column;
    align-items: center;
    overflow: scroll;
    height: 50vw;
    width: 100%;
}

#gallery .comments .comment {
    text-align: center;
    display: flex;
    flex-direction: column;
}

#gallery .comments .comment .MuiTypography-body2 {
    font-family: lupa_pinkyregular !important;
} */