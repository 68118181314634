.expended {
    width: 80%;
    justify-self: center;
    display: inline-grid ;
    padding: 2%;
    max-width: 500px;
    justify-content: center;
}

.products {
    display: inline-block;
}

.expended .makeStyles-media-122 {
    height: 9vh !important;
}

.expended .templates {
    width: 100%;    
    display: flex;
    direction: rtl;
    overflow: auto;
}

.expended .not-available {
    opacity: 0.4 !important;
    background-color: black;
    color: black;
    font-weight: bolder;
    font-size: 3vw;
}

.expended .main-product.not-available {
    font-size: 10vw;
    color: white;
    font-weight: normal !important;
}

.expended .template {
    height: 8vw;
    width: 8vw;
    margin: 1%;
    background-size: cover;
    display: inline-table;
}

.expended .template .stripe {
    width: 100%;
    height: 40%;
    background-size: cover;
}

.expended .MuiCardContent-root {
    padding: 16px;
    height: 14vw;
}

.expended .products .product {
    width: 22vw;
}

.expended .price.MuiCardHeader-root {
    height: 0.5vw;
}

.expended .product .MuiCardHeader-root {
    height: 3vw !important;
}

.expended .main-product.MuiCardMedia-root {
    height: 15vh !important;
}

.expended .field.MuiCardHeader-root {
    position: inherit;
    width: 95%;
    align-items: center;
    text-align: right;
    padding: 1.5vw;
}

.template.selected {
    border-style: solid;
    border-width: 1px 1px 1px 1px;
    border-color: black;
    box-shadow: 2px 2px 2px rgba(44, 43, 43, 0.479);
}

@font-face {
    font-family: 'lupa_pinkyregular';
    src: url('../../Fonts/lupa_pinky-webfont.eot');
    src: url('../../Fonts/lupa_pinky-webfont.eot?#iefix') format('embedded-opentype'),
         url('../../Fonts/lupa_pinky-webfont.woff2') format('woff2'),
         url('../../Fonts/lupa_pinky-webfont.woff') format('woff'),
         url('../../Fonts/lupa_pinky-webfont.ttf') format('truetype'),
         url('../../Fonts/lupa_pinky-webfont.svg#lupa_pinkyregular') format('svg');
    font-weight: normal;
    font-style: normal;
}

.MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated {
    font-family: lupa_pinkyregular;
    color: rgb(2366,10,107);    
    direction: rtl;
}

.text-field {
    direction: rtl;
    justify-content: center;
}

.text-field .MuiFormControl-root.MuiTextField-root{
    width: 95%;
}

.MuiInput-underline:after { 
    border-bottom: solid 2px rgb(2366,10,107) !important;
}  

.MuiInputBase-input.MuiInput-input {
    font-family: 'Lupa Pinky';
    src: local('Lupa Pinky'), url(../../Fonts/Lupa\ Pinky.ttf) format('woff');
    font-size: 4vh;
}

.with-text {
    color: rgb(2366,10,107) !important;
}

.expended .main-product {
    transition: 0.25s;
    display: flex;
    justify-content: center;
    align-items: center;
}